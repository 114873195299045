const pageData = {
    "/": {
      heading: "Welcome to the 26th Association for the Advancement of Animal Breeding and Genetics (AAABG) Conference",
    },
    "/about/": {
      heading: "About AAABG",
    },
    "/accommodation/": {
      heading: "Accommodation",
    },
    "/call-for-papers/": {
      heading: "Call for Papers",
    },
    "/explore-aotearoa/": {
      heading: "Explore Aotearoa",
    },
    "/queenstown/": {
      heading: "Queenstown",
    },
    "/preliminary-program/": {
      heading: "Preliminary Program",
    },
    "/registration/": {
      heading: "Registration",
    },
    "/sponsorship-and-exhibition-opportunities/": {
      heading: "Sponsorship & Exhibition Opportunities",
    },
    "/tiaki-promise/": {
      heading: "Tiaki Promise",
    },
    "/venue/": {
      heading: "Venue",
    },
    "/visa-information/": {
      heading: "Visa Information",
    },
    "/latest-news/": {
      heading: "Latest News",
    },
    "/sponsors-and-exhibitors/": {
      heading: "Sponsors & Exhibitors",
    },
    "/privacy-policy/": {
      heading: "Privacy Policy",
    },
    "/contact/": {
      heading: "Contact Us",
    },
  };
  
  export const getPageData = (path) => {
    const defaultData = {
      backgroundImage: "/images/color-book.webp",
    };
  
    const data = pageData[path] || {};
  
    return {
      ...defaultData,
      ...data,
    };
  };
  